<template>
  <div>
    <div class="main-container">
      <div class="content">
        <v-card class="documents-trail-card">
          <v-row>
            <v-col cols="12" class="documents-table">
              <v-card-title class="d-flex align-center">
                <span class="title-icons mdi mdi-file-multiple"></span>
                <div class="title ml-2">Document Trail</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="p-0 mx-10">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" solo-inverted dense
                    class="search-field"></v-text-field>
                <v-data-table :headers="headers" :items="documents" :search="search">
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template #item.created_timestamp="{ item }">
                    <span v-if="item.created_timestamp">{{ item.created_timestamp }}</span>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.expiration_date="{ item }">
                    <span>{{ item.expiration_date }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { createCoreServices } from '../services/coreService';
import { createDocumentServices } from '../services/documentService';

export default {
  name: 'AuditDocuments',
  setup() {
    const coreServicesInstance = createCoreServices();
    const documentServices = createDocumentServices(coreServicesInstance);

    const search = ref('');
    const documents = ref([]);
    const loading = ref(false);
    const headers = ref(
      [
        { title: 'ID', key: 'id' },
        { title: 'Name', key: 'name' },
        { title: 'Title', key: 'title' },
        { title: 'Status', key: 'status' },
        { title: 'Folder Path', key: 'folder_path' },
        { title: 'Document Type', key: 'document_type' },
        { title: 'Commentary', key: 'commentary' },
        { title: 'Version', key: 'version' },
        { title: 'Created Timestamp', key: 'created_timestamp' },
        { title: 'Document Date', key: 'document_date' },
        { title: 'Expiration Date', key: 'expiration_date' },
        { title: 'Applicant', key: 'applicant' },
        { title: 'Last User', key: 'last_user' },
        { title: 'Study', key: 'study' },
        { title: 'Study Group', key: 'study_group' },
        { title: 'Study Country', key: 'study_country' },
        { title: 'Folder', key: 'folder' },
      ]
    );
    const getDocumentHistory = async () => {
      loading.value = true;
      try {
        const response = await documentServices.getDocumentHistory();
        console.log(JSON.stringify(response));
        documents.value = response.data;
        loading.value = false;
      } catch (error) {
        console.error(error);
        loading.value = false;
      }
    };

    onMounted(async () => {
      await getDocumentHistory();
    });

    return {
      search,
      loading,
      headers,
      documents,
    };
  },
};
</script>

<style scoped>

.main-container {
  margin: 30px 0;
  margin-left: -60px !important;
}

@media (max-width: 1024px) {
  .main-container {
    margin: 15px;
    margin-left: 15px !important;

  }
}

@media (max-width: 768px) {
  .main-container {
    margin: 10px;
    margin-left: 15px !important;


  }
}

@media (max-width: 480px) {
  .main-container {
    margin: 15px;
    margin-left: 15px !important;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
}

.title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.title-icons {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: 35px;
  margin-top: 20px;
}

.documents-trail-card {
  width: 100%;
  max-width: 1100px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.2);
}

.search-field {
  flex-grow: 0;
  width: 70%; 
  margin: 30px 10px;
}

.documents-table {
  justify-content: center;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
