<template>
  <div>
    <div class="main-container">
      <div class="content">
        <v-card class="user-trail-card">
          <v-row>
            <v-col cols="12" class="users-table">
              <v-card-title class="d-flex align-center">
                <span class="title-icons mdi mdi-account-multiple"></span>
                <div class="title ml-2">User Trail</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="p-0 mx-10">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" solo-inverted dense
                    class="search-field"></v-text-field>
                  <v-spacer></v-spacer>
                <v-data-table :headers="headers" :items="users" :search="search">
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.modification="{ item }">
                    <span v-if="item.modification === ''">-</span>
                    <span v-else>{{ item.modification }}</span>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.modification_timestamp="{ item }">
                    <span>{{ item.modification_timestamp.replace('T', ' ') }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';

export default {
  name: 'AuditUsers',
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);

    const search = ref('');
    const users = ref([]);
    const loading = ref(false);
    const headers = [
      { title: 'User', key: 'user_email' },
      { title: 'Event description', key: 'event_description' },
      { title: 'Action', key: 'action' },
      { title: 'Modification', key: 'modification' },
      { title: 'Modifier', key: 'modifier_email' },
      { title: 'Last mod. timestamp', key: 'modification_timestamp' },
    ];

    async function getUserHistory() {
      loading.value = true;
      try {
        const response = await userServicesInstance.getUserHistory();
        users.value = response.data;
      } catch (err) {
        console.error(err);
      } finally {
        loading.value = false;
      }
    }

    onMounted(async () => {
      await getUserHistory();
    });

    return {
      search,
      users,
      headers,
      loading,
    };
  },
};
</script>
<style scoped>
.main-container {
  margin: 30px 0;
  margin-left: -60px !important;
}

@media (max-width: 1024px) {
  .main-container {
    margin: 15px;
    margin-left: 15px !important;

  }
}

@media (max-width: 768px) {
  .main-container {
    margin: 10px;
    margin-left: 15px !important;


  }
}

@media (max-width: 480px) {
  .main-container {
    margin: 15px;
    margin-left: 15px !important;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
}

.title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.title-icons {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: 35px;
  margin-top: 20px;
}

.user-trail-card {
  width: 100%;
  max-width: 1100px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.2);
}

.search-field {
  flex-grow: 0;
  width: 70%; 
  margin: 30px 10px;
}

.users-table {
  justify-content: center;
}

.doc {
  margin-left: 50px;
  padding-top: 80px;
  padding-bottom: 40px;
}

.title_doc {
  margin-top: 20px;
  margin-left: 50px;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
