<template>
    <v-app>
      <v-main class="ma-0">
        <div class="split left tfe-bg-gradient">
          <div class="centered">
            <img class="responsive-img" alt="logo" src="../assets/img/logo_white.png" height="45px" width="150px" />
          </div>
        </div>
        <div class="split right">
          <v-alert v-if="error" type="error" transition="scale-transition">
            {{ errorMessage }}
          </v-alert>
          <div class="resetPassword">
            <v-form class="form-forgot-password" ref="resetForm">
              <div class="div-avatar-style">
                <v-avatar color="primary" size="100">
                  <v-icon size="50" color="white">mdi-lock-reset</v-icon>
                </v-avatar>
              </div>
              <v-row justify="center">
                <v-col class="forgot-pass-colstyle" md="8">
                    <span class="forgot-pass-title">Reset Your Password</span>
                    <v-text-field 
                        v-model="email" 
                        label="E-mail*" 
                        class="form-input pt-5 mt-4" 
                        prepend-icon="mdi-account" 
                        :rules="emailRules" 
                        required 
                        underlined
                    ></v-text-field>
                    
                    <v-text-field 
                        v-model="newPassword" 
                        :type="showPassword ? 'text' : 'password'" 
                        label="New Password*" 
                        class="form-input pt-5" 
                        prepend-icon="mdi-lock" 
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                        @click:append="showPassword = !showPassword" 
                        :rules="passwordRules" 
                        required 
                        underlined
                    ></v-text-field>
                    
                    <v-text-field 
                        v-model="confirmPassword" 
                        :type="showConfirmPassword ? 'text' : 'password'" 
                        label="Confirm Password*" 
                        class="form-input pt-5" 
                        prepend-icon="mdi-lock-check" 
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                        @click:append="showConfirmPassword = !showConfirmPassword" 
                        :rules="passwordMatchRule" 
                        required 
                        underlined
                    ></v-text-field>
                    
                    <div class="pt-5 center-text">
                    <v-btn 
                        outlined 
                        rounded 
                        size="large"
                        @click="resetPassword" 
                        :disabled="!formValid">
                        Set New Password
                    </v-btn>
                    </div>
                </v-col>
            </v-row>
            </v-form>
            <div class="signin-text text-center">
              Remembered your password? <a class="signin-hyperlink" href="/login">Sign in</a>
            </div>
          </div>
        </div>
      </v-main>
    </v-app>
  </template>
  
  
<script>
  import { userServices } from '../services/userService';
  
  export default {
    props: ['token'],
    data() {
      return {
        email: '',
        newPassword: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        error: false,
        errorMessage: '', // Agregar para almacenar el mensaje de error
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be at least 8 characters long',
          v => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
          v => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
          v => /[0-9]/.test(v) || 'Password must contain at least one number',
          v => /[\W_]/.test(v) || 'Password must contain at least one special character',
        ],
        passwordMatchRule: [
          () => this.newPassword === this.confirmPassword || 'Passwords do not match',
        ],
      };
    },
    methods: {
      resetPassword() {
        if (!this.$refs.resetForm.validate()) {
          return;
        }
        const formData = new FormData();
        formData.append('email', this.email);
        formData.append('new_password', this.newPassword);
        formData.append('token', this.token);
        userServices.confirmForgotPassword(formData)
        .then(() => {
          this.$router.push('/login');
        })
        .catch((error) => {
          this.error = true;
          // Asumiendo que la respuesta de error es un objeto JSON con la propiedad 'detail'
          this.errorMessage = error.response.data.detail || 'An unexpected error occurred.';
          setTimeout(() => {
            this.error = false;
          }, 5000);
        });
      },
    },
    computed: {
      formValid() {
        return this.email !== '' && this.newPassword !== '' && this.confirmPassword !== '' && this.newPassword === this.confirmPassword;
      },
    },
  };
</script>
  

<style scoped>
    .forgot-pass-title {
        font-size: 2rem;
        color: #1976D2;
        font-weight: 500;
        text-align: center;
    }
    .div-avatar-style, .reset-pass-colstyle, .center-text, .signin-text {
        margin: 2em auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }
    .form-forgot-password {
        max-width: 1200px;
        min-width: 300px;
        margin: auto;
        width: 100%;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        border-radius: 8px;
    }

    .div-avatar-style, .reset-pass-colstyle {
        margin: 1em auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .center-text, .signin-text {
        width: 100%;
        text-align: center;
        padding-top: 1em;
    }

    .muted-text {
        font-size: 18px;
        color: black;
    }

    .div-avatar-style {
        margin: 1em auto;
        display: flex;
        justify-content: center;
    }

    .reset-pass-colstyle {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .center-text {
        width: 100%;
        text-align: center;
    }
    .div-avatar-style{
        margin:  1em 0;
        width: 100% !important;
        display: flex;
    }
    .change-font{
        margin: auto !important;
    }
    .forgot-pass-colstyle{
        text-align: center !important;
        align-items: center !important;
    }
    .muted-text{
        font-size: 18px;
        color: black;
    }
    .signin-text {
        padding-top: 1em;
        text-decoration: none;
    }
    .color--white{
        color: white;
    }
  </style>
  