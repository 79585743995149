<template>
  <div>
    <div class="main-container">
      <br />
      <div>
        <v-expansion-panels v-model="panels" multiple>
          <v-expansion-panel>
            <v-expansion-panel-title>
              <span class="title-icons mdi mdi-earth"></span>
              <div class="expansion-title">List of Countries</div>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="expansion-content">
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="secondary accent-4" class="mt-0 mr-10 elevation-2" @click="newCountry">
                  <v-icon left class="mr-3">mdi-plus-circle</v-icon>
                  Add Country
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="12" class="country-table">
                  <v-data-table :headers="countryHeaders" :items="countries" :search="countrySearch">
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.actions="{ item }">
                      <div class="actions-container">
                        <v-btn size="small" class="ma-2" color="pink lighten-2" text @click="confirmDeleteCountryDialog(item)">
                          Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel :disabled="isStudiesPanelDisabled">
            <v-expansion-panel-title>
              <span class="title-icons mdi mdi-book-open"></span>
              <div class="expansion-title">List of Studies</div>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="expansion-content">
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="secondary accent-4" class="mt-0 mr-10 elevation-2" @click="newStudy">
                  <v-icon class="mr-3">mdi-plus-box</v-icon> Add Study
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="studyHeaders" :items="processedStudies" :search="studySearch">
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.actions="{ item }">
                      <div class="actions-container">
                        <v-btn size="small" class="ma-2" color="light-blue lighten-2" text @click="updateStudy(item)">
                          Update <v-icon dark class="ml-2">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn size="small" :disabled="!item.is_deletable" class="ma-2" color="pink lighten-2" text @click="confirmDeleteStudyDialog(item)">
                          Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel :disabled="isGroupsPanelDisabled">
            <v-expansion-panel-title>
              <span class="title-icons mdi mdi-account-group"></span>
              <div class="expansion-title">List of Groups</div>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="expansion-content">
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="secondary" class="mt-0 mr-10 elevation-2" @click="newGroup">
                  <v-icon left class="mr-3">mdi-plus-circle</v-icon> Add Group
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="groupHeaders" :items="processedGroups" :search="groupSearch">
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.actions="{ item }">
                      <div class="actions-container">
                        <v-btn size="small" class="ma-2" color="light-blue lighten-2" text @click="updateGroup(item)">
                          Update <v-icon dark class="ml-2">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn size="small" :disabled="!item.is_deletable" class="ma-2" color="pink lighten-2" text
                          @click="confirmDeleteGroupDialog(item)">
                          Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel :disabled="isUsersPanelDisabled">
            <v-expansion-panel-title>
              <span class="title-icons mdi mdi-account-multiple"></span>
              <div class="expansion-title">List of Users</div>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="expansion-content">
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="secondary" class="mt-0 mr-10 elevation-2" @click="newUser">
                  <v-icon left class="mr-3">mdi-account-plus</v-icon> Add User
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="userHeaders" :items="users" :search="userSearch">
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.actions="{ item }">
                      <div class="actions-container">
                        <v-btn size="small" class="ma-2" color="light-blue lighten-2" text @click="updateUser(item)">
                          Update <v-icon dark class="ml-2">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn size="small" :disabled="item.id === user.id || !item.is_active" class="ma-2"
                          color="pink lighten-2" text @click="confirmDeleteUserDialog(item)">
                          Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <br />
      <br />
    </div>
    <!-- <overlay-loader :loading="loading"></overlay-loader> -->
    <v-dialog v-model="newCountryDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">New Country</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="countryCreated.name" label="Country Name*" required underlined></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newCountryDialog = false">Close</v-btn>
          <v-btn outlined rounded :disabled="!countryFormValid" @click="createCountry()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newStudyDialog" max-width="800px" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">New Study</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="studyCreated.name" :rules="studyNameRules" label="Study Name*" required underlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="studyCreated.countryId" :items="countryListItems" label="Select Countries*"
                  item-title="text" item-text="text" item-value="value" multiple required underlined attach chips
                  small-chips>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select v-model="studyCreated.regionId" :items="regionListItems" label="Select Bucket Region*"
                  item-title="text" item-text="text" item-value="value" required underlined attach chips
                  small-chips>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newStudyDialog = false">Close</v-btn>
          <v-btn outlined rounded :disabled="!studyFormValid" @click="createStudy()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newGroupDialog" max-width="800px" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">New Group</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="groupCreated.name" label="Group Name*" required underlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="groupCreated.studyId" :items="studyListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Study*" required></v-select>
              </v-col>
              <v-col cols="12">
                <v-select v-model="groupCreated.regionId" :items="regionListItems" label="Select Bucket Region*"
                  item-title="text" item-text="text" item-value="value" required underlined attach chips
                  small-chips>
                </v-select>
              </v-col>
              <!-- <v-col cols="12">
                <v-select v-model="groupCreated.visible_group_list" :items="groupListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Visible Groups" multiple attach chips></v-select>
              </v-col> -->
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newGroupDialog = false">Close</v-btn>
          <v-btn outlined rounded :disabled="!groupFormValid" @click="createGroup()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newUserDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">New User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="userCreated.email" label="E-mail*" type="email" :rules="emailRules" required
                  underlined></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userCreated.first_name" label="First Name" underlined></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userCreated.last_name" label="Last Name*" underlined persistent-hint
                  required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="userCreated.password" label="Password*" type="password" :rules="passwordRules"
                  underlined required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userCreated.role_id" :items="roleListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Role*" required underlined></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userCreated.study_group_id" :items="groupListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Group*" required underlined></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox v-model="userCreated.is_active" label="User Active*" required></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newUserDialog = false">Close</v-btn>
          <v-btn outlined rounded :disabled="!userCreatedFormValid" @click="createUser()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateStudyDialog" max-width="800px" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Update Study</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="studySelected.name" label="Study Name*" required underlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="studySelected.study_countries" :items="countryListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Countries*" multiple required underlined attach
                  chips small-chips>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateStudyDialog = false">Close</v-btn>
          <v-btn outlined rounded @click="saveStudyChanges()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateGroupDialog" max-width="800px" :retain-focus="false">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Group</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="groupSelected.name" label="Group Name*" required underlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="groupSelected.study" :items="studyListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Study*" required></v-select>
              </v-col>
              <!-- <v-col cols="12">
                <v-select v-model="groupSelected.visible_group_list" :items="groupListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Visible Groups" multiple attach chips></v-select>
              </v-col> -->
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateGroupDialog = false">Close</v-btn>
          <v-btn outlined rounded @click="saveGroupChanges()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateUserDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userSelected.first_name" label="First Name" persistent-hint underlined
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userSelected.last_name" label="Last Name*" persistent-hint underlined
                  required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field underlined v-model="userSelected.email" label="E-mail*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userSelected.role_id" :items="roleListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Role*" required underlined></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userSelected.study_group_id" :items="groupListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Group*" required underlined></v-select>
              </v-col>
              <v-col cols="12" sm="12" v-if="userEmail !== userSelected.email">
                <v-checkbox v-model="userSelected.is_active" label="User Active*" required></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateUserDialog = false">Close</v-btn>
          <v-btn outlined rounded @click="saveDataUser()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCountryDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentCountry.name }} country?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="deleteCountryDialog = false">No</v-btn>
          <v-btn outlined rounded @click="deleteCountry(currentCountry.id)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteStudyDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentStudy.name }} study?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="deleteStudyDialog = false">No</v-btn>
          <v-btn outlined rounded @click="deleteStudy(currentStudy.id)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteGroupDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentGroup.name }} group?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="deleteGroupDialog = false">No</v-btn>
          <v-btn outlined rounded @click="deleteGroup(currentGroup.id)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteUserDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentUser.email }} user?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="deleteUserDialog = false">No</v-btn>
          <v-btn outlined rounded @click="deleteUser(currentUser.id)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import pbkdf2 from 'pbkdf2';
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
import { mapGetters } from 'vuex';
// import { passInfo } from '../utils/exportConsts';
import { roleManager } from "@/store/roleManager";
import { ROLES } from "@/store/roleConstants";
import { toast } from "vue3-toastify";

export default {
  name: "AdminView",
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);

    return {
      userServices: userServicesInstance,
    };
  },
  data() {
    return {
      panels: [0],
      isStudiesPanelDisabled: true,
      isGroupsPanelDisabled: true,
      isUsersPanelDisabled: true,
      showUsers: false,
      drawer: true,
      countrySearch: "",
      studySearch: "",
      groupSearch: "",
      userSearch: "",
      countries: [],
      countryListItems: [],
      groupListItems: [],
      studyListItems: [],
      roleListItems: [],
      regionListItems: [],
      studies: [],
      groups: [],
      users: [],
      userLoggedEmail: "",
      regions: [],
      newCountryDialog: false,
      newStudyDialog: false,
      newGroupDialog: false,
      newUserDialog: false,
      updateStudyDialog: false,
      updateGroupDialog: false,
      updateUserDialog: false,
      deleteCountryDialog: false,
      deleteStudyDialog: false,
      deleteGroupDialog: false,
      deleteUserDialog: false,
      currentCountry: { name: "" },
      currentStudy: "",
      currentGroup: null,
      currentUser: "",
      countryCreated: { name: "" },
      studyCreated: { name: "", studyId: null, countryId: [], regionId: null },
      groupCreated: { name: "", groupId: null, studyId: null, visible_group_list: [], regionId: null, visible_group_list_name: [] },
      userCreated: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role_id: null,
        role_name: "",
        study_group_id: null,
        study_group_name: "",
        is_active: false,
      },
      studySelected: { study_countries: [] },
      groupSelected: { id: null, name: "", study: null, regionId: null, visible_group_list: [], visible_group_list_name: [] },
      userSelected: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role_id: null,
        role_name: "",
        study_group_id: null,
        study_group_name: "",
        is_active: false,
      },
      countryHeaders: [
        { title: "Country", key: "name" },
        { title: "", key: "actions", align: "right" },
      ],
      studyHeaders: [
        { title: "Study Name", key: "name" },
        { title: "Countries", key: "study_countries_name" },
        { title: "Bucket Region", key: "region_description" },
        { title: "", key: "actions", align: "right" },
      ],
      groupHeaders: [
        { title: "Group Name", key: "name" },
        { title: "Study", key: "study_name" },
        // { title: "Visible Groups", key: "visible_group_list_name" },
        { title: "Bucket Region", key: "region_description" },
        { title: "", key: "actions", align: "right" },
      ],
      userHeaders: [
        { title: "Email", key: "email" },
        { title: "First Name", key: "first_name" },
        { title: "Last Name", key: "last_name" },
        { title: "Role", key: "role_name" },
        { title: "Group", key: "study_group_name" },
        { title: "", value: "actions", align: "right" },
      ],
      error: "",
      disabled: false,
      role: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be more than 6 characters",
      ],
      studyNameRules: [
        v => !!v || 'Study Name is required',
        v => /^[a-zA-Z0-9]*$/.test(v) || 'Study Name must not contain special characters or spaces',
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
    userCreatedFormValid() {
      const isEmailValid =
        this.userCreated.email && /.+@.+\..+/.test(this.userCreated.email);
      const isPasswordValid =
        this.userCreated.password && this.userCreated.password.length >= 6;
      return (
        isEmailValid &&
        isPasswordValid &&
        !!this.userCreated.first_name &&
        !!this.userCreated.last_name &&
        this.userCreated.role_id !== undefined &&
        this.userCreated.study_group_id !== undefined &&
        this.userCreated.is_active !== undefined
      );
    },
    countryFormValid() {
      return (
        this.countryCreated &&
        this.countryCreated.name &&
        this.countryCreated.name.length > 0
      );
    },
    groupFormValid() {
      return (
        this.groupCreated &&
        this.groupCreated.name &&
        this.groupCreated.name.length > 0
      );
    },
    studyFormValid() {
      return (
        this.studyCreated &&
        this.studyCreated.name &&
        this.studyCreated.name.length > 0
      );
    },
    canExpandStudies() {
      return this.countries.length > 0;
    },
    canExpandGroups() {
      return this.studies.length > 0 && this.canExpandStudies;
    },
    canExpandUsers() {
      return this.groups.length > 0 && this.canExpandGroups;
    },
    processedStudies() {
      if (!Array.isArray(this.studies)) {
        console.error('Expected this.studies to be an array, but got:', this.studies);
        return [];
      }
      return this.studies.map(study => ({
        ...study,
        study_countries_name: this.getCountryNamesByIds(study.study_countries || []),
        region_description: this.getRegionDescriptionById(study.regionId),
      }));
    },
    processedGroups() {
      return this.groups.map(group => ({
        ...group,
        study_name: this.getStudyNameById(group.study),
        region_description: this.getRegionDescriptionById(group.regionId),
        // visible_group_list_name: (group.visible_group_list || []).map(groupId => this.getGroupNameById(groupId)).join(', ')
      }));
    },
    userEmail() {
      return this.userLoggedEmail
    }
  },
  async mounted() {
    this.getRoles();
    await this.userServices
      .checkUserData()
      .then((response) => {
        this.userLoggedEmail = response.data.email;
        if (response.data.role !== ROLES.ADMIN) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    await Promise.all([
      this.getCountries(),
      this.getRegion(),
      this.getStudies(),
      this.getGroups(),
      this.getUsers(),
    ]);
    this.initialPanelStates();
  },
  watch: {
    countries(newVal) {
      this.isStudiesPanelDisabled = newVal.length === 0;
      this.updatePanels();
    },
    studies(newVal) {
      this.isGroupsPanelDisabled =
        this.countries.length === 0 || newVal.length === 0;
      this.updatePanels();
    },
    groups(newVal) {
      this.isUsersPanelDisabled =
        this.countries.length === 0 ||
        this.studies.length === 0 ||
        newVal.length === 0;
      this.updatePanels();
    },
  },
  methods: {
    getRoles() {
      this.roleListItems = roleManager.roleList().map((role) => ({
        value: role.id,
        text: role.text,
      }));
    },
    showElementsInToolbar(elementsToShow) {
      if (elementsToShow === "users") {
        this.showUsers = !this.showUsers;
      }
    },
    newCountry() {
      this.countryCreated = { name: "" };
      this.newCountryDialog = true;
    },
    newStudy() {
      this.studyCreated = { name: "", studyId: null, countryId: [], regionId: null };
      this.countryListItems = this.countries.map((country) => ({
        value: country.id,
        text: country.name,
      }));
      this.newStudyDialog = true;
    },
    newGroup() {
      this.groupCreated = { name: "", groupId: null, studyId: null, regionId: null, visible_group_list: [], visible_group_list_name: [] };
      this.studyListItems = this.studies.map((study) => ({
        value: study.id,
        text: study.name,
      }));
      this.newGroupDialog = true;
    },
    newUser() {
      this.userCreated = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role_id: null,
        role_name: "",
        study_group_id: null,
        study_group_name: "",
        is_active: false,
      };
      this.roleListItems = roleManager.roleList().map((role) => ({
        value: role.id,
        text: role.text,
      }));
      this.newUserDialog = true;
    },
    createCountry() {
      this.loading = true;
      this.userServices
        .createCountry(this.countryCreated)
        .then((response) => {
          this.countries.push(response.data);
          this.loading = false;
          this.newCountryDialog = false;
          this.countryCreated = {};
          toast("Country created successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          toast("Error creating country.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
    },
    createStudy() {
      if (this.studyFormValid) {
        this.loading = true;
        console.log(JSON.stringify(this.studyCreated));
        this.userServices
          .createStudy(this.studyCreated)
          .then((response) => {
            this.studies.push(response.data);
            this.loading = false;
            this.newStudyDialog = false;
            this.studyCreated = { name: "", studyId: null, countryId: [], regionId: null };
            toast("Study created successfully.", {
              type: "success",
              transition: "slide",
              dangerouslyHTMLString: true
            });
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            toast("Error creating study.", {
              type: "error",
              transition: "slide",
              dangerouslyHTMLString: true
            });
          });
      }
    },
    createGroup() {
      if (this.groupFormValid) {
        this.userServices
          .createGroup(this.groupCreated)
          .then((response) => {
            this.groups.push(response.data);
            this.groupListItems.push({
              value: response.data.id,
              text: response.data.name,
            });
            this.loading = false;
            this.newGroupDialog = false;
            this.groupCreated = { name: "", groupId: null, studyId: null, regionId: null, visible_group_list: [], visible_group_list_name: [] };
            toast("Group created successfully.", {
              type: "success",
              transition: "slide",
              dangerouslyHTMLString: true
            });
          })
          .catch((error) => {
            console.log(error);
            toast("Error creating group.", {
              type: "error",
              transition: "slide",
              dangerouslyHTMLString: true
            });
            this.loading = false;
          });
      }
    },
    createUser() {
      this.loading = true;
      console.log(JSON.stringify(this.userCreated));
      this.userServices
        .createUser(this.userCreated)
        .then((response) => {
          const user = {
            ...response.data,
            study_group_id: response.data.study_group.id,
            study_group_name: response.data.study_group.name,
            role_id: roleManager.roleList().find(role => role.value == response.data.role).id,
            role_name: roleManager.roleList().find(role => role.value == response.data.role).text,
          };
          this.users.push(user);
          this.loading = false;
          this.newUserDialog = false;
          this.userCreated = {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role_id: null,
            role_name: "",
            study_group_id: null,
            study_group_name: "",
            is_active: false,
          };
          toast("User created successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          toast("Error creating the user.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
    },
    async getCountries() {
      const response = await this.userServices.getCountries();
      /* eslint-disable no-param-reassign */
      this.countries = response.data || [];
      if (this.countries.length > 0) {
        /* eslint-enable no-param-reassign */
        this.countries.sort((a, b) => {
          const first_name = a.name.toLowerCase();
          const secondName = b.name.toLowerCase();
          return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
        });
      }
    },
    async getRegion() {
      const response = await this.userServices.getRegion();
      if (response.data.length > 0) {
        this.regions = response.data;
        this.regionListItems = this.regions.map((region) => ({
          value: region.id,
          text: region.region_description,
        }));
      }
    },
    async getStudies() {
      try {
        const response = await this.userServices.getStudies();
        /* eslint-disable no-param-reassign */
        this.studies = response.data || [];
        if (this.studies.length > 0) {
          /* eslint-enable no-param-reassign */
          this.studies.sort((a, b) => {
            const first_name = a.name.toLowerCase();
            const secondName = b.name.toLowerCase();
            return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
          });
        }
      } catch (error) {
        console.error('Failed to fetch studies:', error);
        this.studies = [];
      }
    },
    async getGroups() {
      const response = await this.userServices.getGroups();
      /* eslint-disable no-param-reassign */
      console.log(JSON.stringify(response.data));
      console.log(JSON.stringify('response.data'));
      this.groups = response.data || [];

      if (this.groups.length > 0) {
        this.groupListItems = this.groups.map((group) => ({
          value: group.id,
          text: group.name,
        }));
        /* eslint-enable no-param-reassign */
        this.groups.sort((a, b) => {
          const first_name = a.name.toLowerCase();
          const secondName = b.name.toLowerCase();
          return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
        });
      }
    },
    async getUsers() {
      const response = await this.userServices.getUsers();
      this.users = response.data.map((user) => ({
        ...user,
        role_id: this.roleListItems.find(role => role.text.toLowerCase() === user.role).value,
        role_name: this.roleListItems.find(role => role.text.toLowerCase() === user.role).text,
        study_group_id: user.study_group ? user.study_group.id : null,
        study_group_name: user.study_group ? user.study_group.name : null,
      }));
      /* eslint-enable no-param-reassign */
      this.users.sort((a, b) => {
        const first_name = a.last_name.toLowerCase();
        const secondName = b.last_name.toLowerCase();
        // eslint-disable-next-line
        return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
      });
    },
    /** Show delete country.
     * @param {Object} country Selected country object
     */
    confirmDeleteCountryDialog(country) {
      this.currentCountry = { ...country };
      this.deleteCountryDialog = true;
    },
    /** Show delete study.
     * @param {Object} study Selected user object
     */
    confirmDeleteStudyDialog(study) {
      this.currentStudy = { ...study };
      this.deleteStudyDialog = true;
    },
    /** Show delete group.
     * @param {Object} group Selected group object
     */
    confirmDeleteGroupDialog(group) {
      this.currentGroup = { ...group };
      this.deleteGroupDialog = true;
    },
    /** Show delete dialog.
     * @param {Object} user Selected user object
     */
    confirmDeleteUserDialog(user) {
      this.currentUser = { ...user };
      this.deleteUserDialog = true;
    },
    /** Delete country.
     * @param {int} countryId Country id to delete
     */
    deleteCountry(countryId) {
      this.countries = this.countries.filter(
        (country) => country.id !== countryId
      );
      this.error = "Country deleted";
      this.userServices
        .deleteCountry(countryId)
        .then(() => {
          this.error = "Country deleted";
          this.countries = this.countries.filter(
            (country) => country.id !== countryId
          );
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail :"Error deleting country.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteCountryDialog = false;
        });
    },
    /** Delete study.
     * @param {int} studyId Study id to delete
     */
    deleteStudy(studyId) {
      this.userServices
        .deleteStudy(studyId)
        .then(() => {
          this.error = "Study deleted";
          this.studies = this.studies.filter((study) => study.id !== studyId);
        })
        .catch((error) => {
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail :"Error deleting study.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteStudyDialog = false;
        });
    },
    /** Delete user.
     * @param {int} groupId Group id to delete
     */
    deleteGroup(groupId) {
      this.userServices
        .deleteGroup(groupId)
        .then(() => {
          this.error = "Group deleted";
          this.groups = this.groups.filter((group) => group.id !== groupId);
        })
        .catch((error) => {
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail :"Error deleting group.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteGroupDialog = false;
        });
    },
    /** Delete user.
     * @param {int} userId User id to delete
     */
    deleteUser(userId) {
      this.userServices
        .deleteUser(userId)
        .then(() => {
          this.error = "User deleted";
          this.users = this.users.filter((user) => user.id !== userId);
          toast("User deleted successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail :"Error deleting user.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteUserDialog = false;
        });
    },
    /** Show update study dialog
     * @param {Object} study Objectto update
     */
    updateStudy(study) {
      console.log(JSON.stringify(study));
      this.studySelected = { ...study };
      this.studySelected.study_countries = study.study_countries.map((id) =>
        Number(id)
      );

      this.countryListItems = this.countries.map((country) => ({
        value: country.id,
        text: country.name,
      }));
      this.updateStudyDialog = true;
    },
    /** Show update group dialog
     * @param {Object} group Objectto update
     */
    updateGroup(group) {
      this.groupSelected = {
        ...group,
        study: group.study,
      };
      this.studyListItems = this.studies.map((study) => ({
        value: study.id,
        text: study.name,
      }));
      this.updateGroupDialog = true;
    },
    /** Show update user dialog
     * @param {Object} user Objectto update
     */
    updateUser(user) {
      this.userSelected = {
        ...user,
        role_id: this.roleListItems.find(role => role.text.toLowerCase() === user.role).value,
        role_name: this.roleListItems.find(role => role.text.toLowerCase() === user.role).text,
        study_group_id: user.study_group.id,
        study_group_name: user.study_group.name,
      };
      this.updateUserDialog = true;
    },
    saveStudyChanges() {
      this.userServices
        .updateStudy(this.studySelected.id, this.studySelected)
        .then(() => {
          const foundIndex = this.studies.findIndex(
            (study) => study.id === this.studySelected.id
          );
          this.studies.splice(foundIndex, 1, { ...this.studySelected });
          this.studySelected = {};
          this.updateStudyDialog = false;
          toast("Study updated successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error updating study.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
      this.updateStudyDialog = false;
    },
    saveGroupChanges() {
      console.log(JSON.stringify(this.groupSelected));
      this.userServices
        .updateGroup(this.groupSelected.id, this.groupSelected)
        .then(() => {
          const foundIndex = this.groups.findIndex(
            (group) => group.id === this.groupSelected.id
          );
          this.groups.splice(foundIndex, 1, { ...this.groupSelected });
          this.groupSelected = { id: null, name: "", study: null, regionId: null, visible_group_list: [], visible_group_list_name: [] };
          this.updateStudyDialog = false;
          toast("Group updated successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error updating group.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
      this.updateGroupDialog = false;
    },
    saveDataUser() {
      const userSelectedData = {
        ...this.userSelected,
        role: (this.roleListItems.find(role => role.value === this.userSelected.role_id).text).toLowerCase(),
        study_group: this.userSelected.study_group_id
      };
      this.userServices
        .updateUser(this.userSelected.id, userSelectedData)
        .then((response) => {
          const foundIndex = this.users.findIndex(
            (user) => user.id === this.userSelected.id
          );
          const user = {
            ...response.data,
            role_id: this.roleListItems.find(role => role.text.toLowerCase() === response.data.role).value,
            role_name: this.roleListItems.find(role => role.text.toLowerCase() === response.data.role).text,
            study_group_id: response.data.study_group.id,
            study_group_name: response.data.study_group.name,
          };
          this.users.splice(foundIndex, 1, { ...user });
          this.userSelected = {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role_id: null,
            role_name: "",
            study_group_id: null,
            study_group_name: null,
            is_active: false,
          };
          this.error = response.data.first_name;
          this.updateUserDialog = false;
          toast("User updated successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error updating user.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
      this.updateUserDialog = false;
    },
    updatePanels() {
      let newPanels = [0];

      if (!this.isStudiesPanelDisabled) {
        newPanels.push(1);
      }

      if (!this.isGroupsPanelDisabled) {
        newPanels.push(2);
      }

      if (!this.isUsersPanelDisabled) {
        newPanels.push(3);
      }

      this.panels = newPanels;
    },
    initialPanelStates() {
      this.isStudiesPanelDisabled = this.countries.length === 0;
      this.isGroupsPanelDisabled =
        this.countries.length === 0 || this.studies.length === 0;
      this.isUsersPanelDisabled =
        this.countries.length === 0 ||
        this.studies.length === 0 ||
        this.groups.length === 0;
      this.updatePanels();
    },
    getCountryNamesByIds(countryIds) {
      return countryIds
        .map(id => this.countries.find(country => country.id === id)?.name)
        .filter(name => name !== undefined)
        .join(', ');
    },
    getStudyNameById(studyId) {
      const study = this.studies.find(s => s.id === studyId);
      return study ? study.name : '';
    },
    getGroupNameById(groupId) {
      const group = this.groups.find(g => g.id === groupId);
      return group ? group.name : '';
    },
    getRegionDescriptionById(regionId) {
      console.log('regionId');
      console.log(regionId);
      const region = this.regions.find(r => r.id === regionId);
      console.log(JSON.stringify(region));
      return region ? region.region_description : '';
    },
  },
};
</script>
<style scoped>
.main-container {
  margin: 0;
}
@media (max-width: 1024px) {
  .main-container {
    margin: 15px;
  }
}
@media (max-width: 768px) {
  .main-container {
    margin: 10px;
  }
}
@media (max-width: 480px) {
  .main-container {
    margin: 5px;
  }
}

.v-expansion-panel {
  width: 100%;
  max-width: 1000px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.2);
  margin-bottom: 20px;
}

.expansion-content {
  padding: 2em 4em 5em 4em;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.expansion-title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.v-expansion-panel--default > .v-expansion-panel-header {
  transition: background-color 0.3s ease;
}

.v-expansion-panel--default > .v-expansion-panel-header:not(.v-expansion-panel-header--active):hover {
  background-color: #f4f4f4;
}

.title-icons {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 12px;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
