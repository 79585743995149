import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    user: null,
    isLoading: false,
    isAppLoaded: false,
    activeRoute: '/',
  },
  mutations: {
    resetState(state) {
      state.user = null;
      state.isAppLoaded = false;
    },
    setUser(state, user) {
      state.user = user;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setAppLoaded(state) {
      state.isAppLoaded = true;
    },
    setActiveRoute(state, route) {
      state.activeRoute = route;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setLoading({ commit }, isLoading) {
      commit('setLoading', isLoading);
    },
    setAppLoaded({ commit }) {
      commit('setAppLoaded');
    },
    validateToken({ commit }, token) {
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/users/me/`, {
        headers: { 'Authorization': `Bearer ${token}` }
      }).then(response => {
        console.log(JSON.stringify(response));
        commit('setUser', response.data);
        commit('setAppLoaded');
      }).catch(error => {
        console.error("Token validation failed", error);
        this.$router.push('/login');
      });
    },
    updateActiveRoute({ commit }, route) {
      commit('setActiveRoute', route);
    }
  },
  getters: {
    user: state => state.user,
    isLoading: state => state.isLoading,
    isUserLoggedIn: state => !!state.user,
    isAppLoaded: state => state.isAppLoaded,
    activeRoute: state => state.activeRoute
  }
});