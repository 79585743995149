
<template>
  <v-app>
    <div class="forgotPassword">
      <img alt="logo" src="../assets/img/logo_color.png" width="180px">
      <v-form class="form-forgot-password">
        <div class="div-avatar-style">
          <v-avatar color="white" class="change-font" size="100">
            <v-icon size="50" color="primary">mdi-email</v-icon>
          </v-avatar>
        </div>
        <v-row>
          <v-col class="forgot-pass-colstyle">
            <p class="forgot-pass-title">Check your email</p>
            <p>We sent you a email with a new password.</p>
          </v-col>
        </v-row>
      </v-form>
      <div class="signin-text aligned-end">
        Didn't receive the email?
        <a class="signin-hyperlink" href="/forgotPassword">Click to resend</a>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
  .forgotPassword {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
</style>