<template>
  <v-card
    class="mx-auto side-img"
    variant="flat"
    max-width="300"
  >
    <v-list>
      <v-list-item>
        <v-img class="mt-4" src="@/assets/img/logo.png" height="70" contain></v-img>
      </v-list-item>
    </v-list>
    <v-divider class="my-2"></v-divider>
    <v-list
      :lines="false"
      density="compact"
      nav
    >
    <v-list-item
      v-for="item in menuItems" 
      :key="item.title" 
      :active="isRouteActive(item.path)"
      link 
      :to="item.path"
      color="blue-darken-3"
      variant="text"
      lines="two"
      density="comfortable"
      :border="true"
    >
      <template v-slot:prepend>
        <v-icon :icon="item.icon"></v-icon>
      </template>
      <v-list-item-title class="custom-text-item-size">
        {{ item.text }}
      </v-list-item-title>
    </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { roleManager } from '@/store/roleManager';
import { mapGetters } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      menuItems: [
        { text: 'HOME', path: '/home', icon: 'mdi-home' },
      ],
      particlesOptions: {
        particles: {
          number: {
            value: 80,
          },
          size: {
            value: 3,
          },
          move: {
            enable: true,
            speed: 3,
          },
          opacity: {
            value: 0.5,
          },
          line_linked: {
            enable: true,
            distance: 150,
          },
        },
      }

    };
  },
  mounted() {
    this.controlPanelTab();
  },
  methods: {
    controlPanelTab() {
      if (roleManager.isAdmin() || roleManager.isStaff()) {
        this.menuItems.push(
          { text: 'DOCUMENTS', path: '/document', icon: 'mdi-file-document' },
          { text: 'ADMIN', path: '/admin', icon: 'mdi-account-supervisor' },
          { text: 'USER TRAIL', path: '/audit-users', icon: 'mdi-account-clock' },
          { text: 'DOCUMENT TRAIL', path: '/audit-documents', icon: 'mdi-file-document' }
        );
      }
      if (roleManager.isUploader() || roleManager.isVerifier() || roleManager.isResearcher()) {
        this.menuItems.push(
          { text: 'DOCUMENTS', path: '/document', icon: 'mdi-file-document' },
        );
      }
    },
  },
  computed: {
    ...mapGetters(['activeRoute']),
    isRouteActive() {
      return (path) => this.activeRoute === path;
    }
  }
};
</script>

<style scoped>
  .logo {
    margin-top: 0;
  }
  .side-img {
    margin-top: 0 !important;
    padding-top: 0;
    height: 100%;
    background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.95) 35%,
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0.85) 85%,
        rgba(255, 255, 255, 1)
      ),
      url('@/assets/img/header.jpg') !important;
    background-size: cover;
    background-position: 42% 105%!important;
    background-repeat: no-repeat;
    position: relative;
  }

  .align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-text-item-size {
    font-size: 1em;
    font-weight: 600;
  }

  .particles-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
  }

</style>
