import { roleManager } from '@/store/roleManager';

export const createUserServices = (coreServices) => {
  return {
    async Login(data) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/login/`;
      return coreServices.post(url, data, '');
    },
    setNewPassword(formData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/password/reset/confirm/`;
      const headers = coreServices.getRequestHeader();
      delete headers.Authorization;
      return coreServices.post(url, formData, headers);
    },
    setNewPasswordUpdated(formData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/set-password-updated/`;
      return coreServices.post(url, formData, coreServices.getRequestHeader());
    },
    resetPassword(formData) {
      const header = coreServices.getRequestHeader();
      delete header.Authorization;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/password/reset/`;
      return coreServices.post(url, formData, header);
    },
    forgotPassword(formData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/forgot-password/`;
      const header = coreServices.getRequestHeader();
      delete header.Authorization;
      return coreServices.post(url, formData, header);
    },
    confirmForgotPassword(formData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/forgot-password/confirm/`;
      const header = coreServices.getRequestHeader();
      delete header.Authorization;
      return coreServices.post(url, formData, header);
    },
    changePassword(formData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/update-password/`;
      return coreServices.post(url, formData, coreServices.getRequestHeader());
    },
    /** Get the region */
    getRegion() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/masterdata/bucket-region/`;
      return coreServices.get(url);
    },
    /** Get list of countries */
    getCountries() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-countries/`;
      return coreServices.get(url);
    },
    /** Get list of studies */
    getStudies() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/studies/`;
      return coreServices.get(url);
    },
    /** Get list of studies */
    getGroups() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/`;
      return coreServices.get(url);
    },
    /** Get list of users */
    getUsers() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/`;
      return coreServices.get(url);
    },
    getUserApprovers() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/approvers/`;
      return coreServices.get(url);
    },
    /** Create a new country
   * @param {Object} data dictionary of the user
   */
    createCountry(data) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-countries/`;
      return coreServices.post(url, data, coreServices.getRequestHeader());
    },
    /** Create a new group
     * @param {Object} data dictionary of the user
     */
    createGroup(data) {
      const dataRequest =
      {
        name: data.name,
        visible_group_list: data.visible_group_list,
        bucket_region: data.regionId,
        study: data.studyId,
        folder: null, // TODO 
        is_deletable: true
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/`;
      return coreServices.post(url, dataRequest, coreServices.getRequestHeader());
    },
    /** Create a new study
     * @param {Object} data dictionary of the user
    */
    createStudy(data) {
      console.log(JSON.stringify(data));
      const postData = {
        name: data.name,
        study_countries: data.countryId,
        bucket_region: data.regionId
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/studies/`;
      return coreServices.post(url, postData, coreServices.getRequestHeader());
    },
    /** Create a new user
     * @param {Object} data dictionary of the user
     */
    createUser(data) {
      const dataRequest =
      {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        role: roleManager.roleList().find(role => role.id == data.role_id).value,
        is_active: data.is_active,
        password: data.password,
        study_group: data.study_group_id,
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/`;
      return coreServices.post(url, dataRequest, coreServices.getRequestHeader());
    },
    /** Update a study
     * @param {Object} data dictionary of the study
     * @param {int} studyId Study id to update
     */
    updateStudy(studyId, data) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/studies/${studyId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.put(url, data, header);
    },
    /** Update a group
     * @param {Object} data dictionary of the study
     * @param {int} groupId Study id to update
     */
    updateGroup(groupId, data) {
      console.log(JSON.stringify(data));
      const dataRequest =
      {
        name: data.name,
        study: data.study,
        visible_group_list: data.visible_group_list,
        // folder: null,
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${groupId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.put(url, dataRequest, header);
    },
    /** Update a user
     * @param {Object} data dictionary of the user
     * @param {int} userId User id to update
     */
    updateUser(userId, data) {
      console.log(JSON.stringify(data));
      const dataRequest =
      {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        role: data.role,
        is_active: data.is_active,
        study_group: data.study_group,
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.put(url, dataRequest, header);
    },
    /** Delete country
     * @param {int} countryId User id to delete
     */
    deleteCountry(countryId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-countries/${countryId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.delete(url, header);
    },
    /** Delete study
     * @param {int} studyId User id to delete
     */
    deleteStudy(studyId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/studies/${studyId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.delete(url, header);
    },
    /** Delete group
     * @param {int} groupId User id to delete
     */
    deleteGroup(groupId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${groupId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.delete(url, header);
    },
    /** Delete user
     * @param {int} userId User id to delete
     */
    deleteUser(userId) {
      console.log(userId);
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.delete(url, header);
    },
    checkUserData() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/me/`;
      return coreServices.get(url);
    },
    updateUsername(userId, data) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/update-username/`;
      const header = coreServices.getRequestHeader();
      return coreServices.put(url, data, header);
    },
    getUserHistory() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/history/`;
      return coreServices.get(url);
    },
  };
};
