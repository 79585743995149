<template>
  <div class="pdf-div" v-if="files.length > 0">
    <v-row>
      <v-col v-for="(file, index) in files" :key="`file-${index}`" sm="12">
        <div class="pdf-header">
          <v-row class="pdf-title-row">
            <v-col>
              <div class="pdf-title">{{ file.title }}</div>
            </v-col>
          </v-row>
          <v-row justify="end" class="file-controls-row">
            <v-col cols="auto">
              <div class="file-controls">
                <v-btn icon x-small @click="zoomIn(index)">
                  <v-icon size="18">mdi-magnify-plus</v-icon>
                </v-btn>
                <v-btn icon x-small @click="zoomOut(index)">
                  <v-icon size="18">mdi-magnify-minus</v-icon>
                </v-btn>
                <v-btn  icon x-small v-if="canDownloadPdf" @click="downloadPdf(file, `documento-${index}.pdf`)">
                  <v-icon size="18">mdi-download</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="pdf-container">
          <v-col class="pa-2 my-7" v-for="(canvasId, pageIndex) in file.pages" :key="`canvas-${index}-${pageIndex}`">
            <canvas :id="`canvas-${index}-${pageIndex}`" class="the-canvas"></canvas>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker';
import { roleManager } from '@/store/roleManager';

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;

export default {
  name: 'PdfViewer',
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scales: {},
    };
  },
  mounted() {
    this.files.forEach((file, index) => {
      this.scales[index] = 1;
      this.getPdf(file, index);
    });
  },
  computed: {
    canDownloadPdf() {
      return roleManager.isResearcher();
    },
  },
  methods: {
    async getPdf(file, fileIndex) {
      let loadingTask;
      if (file.url) {
        loadingTask = pdfjsLib.getDocument(file.url);
      } else if (file.base64) {
        loadingTask = pdfjsLib.getDocument({ data: atob(file.base64) });
      }

      const pdf = await loadingTask.promise;
      file.pages = Array.from({ length: pdf.numPages }, (_, i) => i + 1);

      for (let pageIndex = 1; pageIndex <= pdf.numPages; pageIndex++) {
        const page = await pdf.getPage(pageIndex);
        this.renderPage(page, fileIndex, pageIndex - 1, this.scales[fileIndex]);
      }
    },

    async renderPage(page, fileIndex, pageIndex, scale) {
      const canvas = document.getElementById(`canvas-${fileIndex}-${pageIndex}`);
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      const viewport = page.getViewport({ scale: scale });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext);
    },

    zoomIn(fileIndex) {
      if(this.scales[fileIndex] > 2) return;
      this.scales[fileIndex] *= 1.2;
      this.rerenderPdf(fileIndex);
    },

    zoomOut(fileIndex) {
      if(this.scales[fileIndex] < 0.2) return;
      this.scales[fileIndex] /= 1.2;
      this.rerenderPdf(fileIndex);
    },

    async rerenderPdf(fileIndex) {
      await this.getPdf(this.files[fileIndex], fileIndex);
    },

    downloadPdf(file, filename) {
      let link = document.createElement('a');
      if (file.url) {
        link.href = file.url;
      } else if (file.base64) {
        link.href = `data:application/pdf;base64,${file.base64}`;
      }
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.pdf-div {
  margin: 30px 4em!important;
}

.pdf-header {
  width: 100%;
}

.pdf-title-row {
  width: 100%;
  padding: 0;
  margin-top:-25px!important;
}

.file-controls-row {
  width: 100%;
  margin-bottom: 10px;
  margin-bottom: 0;
  padding: 0;
  margin-top:-15px!important;
}

.pdf-title {
  text-align: center;
  font-size: 1.7em;
}

.file-controls {
  display: flex;
  gap: 8px;
}

.the-canvas {
  border: 1px solid black;
  margin-bottom: 10px;
}

.pdf-container {
  overflow: auto;
  max-height: 800px;
  position: relative;
  background: none;
}
</style>
