<template>
  <div>
    <div class="main-container">
    <div class="content">
      <v-alert class="alert-box" v-if="alert" type="error" transition="scale-transition">{{ error }}</v-alert>
      <v-container style="max-width: 900px;">
        <v-card v-show="items.length > 1" outlined class="preclassification-title-card" elevation="13">
          <v-toolbar flat class="preclassification-toolbar">
            <v-toolbar-title class="preclassification-toolbar-style">Same configuration on all files?</v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
              <v-switch v-model="files_w_same_configuration" label="Same Configuration" hide-details></v-switch>
            </div>
          </v-toolbar>
        </v-card>
        <div v-for="(file, index) in items" :key="file.id">
          <v-card :id="'file-'+file.id" outlined elevation="5" class="preclassification-card" v-show="canShowFileCard(index)">
            <v-card-title class="ml-7">File title:</v-card-title>
            <v-card-text>
              <v-row class="preclassification-row" v-for="(title, titleIndex) in getTileTitles(index)" :key="title.id">
                <v-col cols="12" md="9">
                  <v-text-field v-model="title.title" readonly underlined v-on:keyup="onKeyup(files_w_same_configuration ? titleIndex : index)" :label="'File name '+(files_w_same_configuration ? titleIndex+1 : index+1)"></v-text-field>
                  <span>
                    <v-progress-circular v-if="title.file_exists === null" color="primary" indeterminate size="20" width="3" style="float: left;"></v-progress-circular>
                  </span>
                </v-col>
                <!-- <v-col cols="2">
                  <v-text-field v-model="title.document_type" underlined readonly :label="'Ext. '+(files_w_same_configuration ? titleIndex+1 : index+1)" class="no-border"></v-text-field>
                </v-col> -->
              </v-row>
              <v-row class="preclassification-row">
                <p class="ml-3 mt-10 mb-0 preclassification-file-title">File configuration:</p>
              </v-row>
              <v-row class="preclassification-row">
                <v-col cols="12" md="9">
                  <div class="label-container">
                      <strong>Study </strong>
                      <div>{{ file.study.name }}</div>
                    </div>
                    <div class="label-container">
                      <strong>Study Group </strong>
                      <div>{{ file.study_group.name }}</div>
                    </div>
                    <div class="label-container">
                      <strong>Study Country</strong>
                      <div v-for="countryId in file.study.study_countries" :key="countryId">
                        {{ getCountryNameById(countryId) }}
                      </div>
                    </div>
                  <!-- <div class="select-style">
                    <strong>Commentary</strong>
                    <v-text-field 
                      v-model="items[index].commentary" 
                      label="Commentary" 
                      underlined></v-text-field>
                  </div> -->
                  <div class="select-style">
                    <strong>Document Date</strong>
                    <v-text-field 
                      v-model="items[index].document_date" 
                      label="Document Date" 
                      type="date" 
                      underlined></v-text-field>
                  </div>
                  <v-checkbox label="Document expire?" v-model="items[index].does_it_expire" @change="items[index].expiration_date = null"></v-checkbox>
                  <div v-if="items[index].does_it_expire">
                    <v-text-field 
                      v-model="items[index].expiration_date" 
                      label="Expiration date*" 
                      type="date" 
                      underlined></v-text-field>
                  </div>
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br/>
        </div>
        <div class="text-right">
          <v-btn :id="'file-btn-save'" :disabled="checkDisabledBtn" outlined rounded @click="submitSave()">Save file Configurations</v-btn>
        </div>
      </v-container>
    </div>
  </div>
  </div>
</template>

<script>
import { createCoreServices } from '../services/coreService';
import { createFileServices } from "../services/preStudyService";

export default {
  name: 'PreClassificationForm',
  components: {
  },
  data() {
    return {
      items: [],
      study_items: [],
      study_site_items: [],
      study_country_items: [],
      artifact_items: [],
      files_w_same_configuration: false,
      inputIndex: -1,
      typingTimer: null,
      doneTypingInterval: 1000,
      alert: false,
      error: '',
			coreServicesInstance: null,
      fileServices: null
    };
  },
  computed: {
    checkDisabledBtn() { return this.getWrongFilesList().length > 0; },
  },
  async mounted() {
    this.coreServicesInstance = createCoreServices();
    this.fileServices = createFileServices(this.coreServicesInstance);
    await Promise.all([
      this.getCountries(),
      this.getFilesToClassify(),
    ]);
  },
  methods: {
    async getCountries() {
      const response = await this.fileServices.getStudyCountryList();
      /* eslint-disable no-param-reassign */
      this.study_country_items = response.data || [];
      console.log(JSON.stringify(this.study_country_items[0].id));
      
      if (this.study_country_items.length > 0) {
        /* eslint-enable no-param-reassign */
        this.study_country_items.sort((a, b) => {
          const first_name = a.name.toLowerCase();
          const secondName = b.name.toLowerCase();
          return first_name < secondName ? -1 : (first_name > secondName ? 1 : 0);
        });
      }
    },
    async getFilesToClassify() {
      await this.fileServices.neededClassifyFiles()
        .then((response) => {
          this.items = response.data.map((file) => {
              const item = {
                ...file,
                document_type: file.document_type.startsWith('application/') ? file.document_type.split('/')[1] : ''
              };
              item.study = file.study;
              item.applicant = file.applicant.first_name + ' ' + file.applicant.last_name;
              return item;
          });
        })
        .catch((error) => { console.error('error', error); });
    },
    async getGroups() {
      const response = await this.fileServices.getStudyGroupList();
      /* eslint-disable no-param-reassign */
      this.groups = response.data || [];
      if (this.groups.length > 0) {
        this.groupListItems = this.groups.map(group => ({
          value: group.id,
          text: group.name
        }));
        /* eslint-enable no-param-reassign */
        this.groups.sort((a, b) => {
          const first_name = a.name.toLowerCase();
          const secondName = b.name.toLowerCase();
          return first_name < secondName ? -1 : (first_name > secondName ? 1 : 0);
        });
      }
    },
    canShowFileCard(index) {
      return index === 0 ? true : !this.files_w_same_configuration;
    },
    isIndexFileOk(index) {
      const file = this.files_w_same_configuration ? this.items[0] : this.items[index];
      const fileExistsCheck = file.file_exists !== false;
      const studyCheck = file.study !== null && file.study !== undefined;
      const studyGroupCheck = file.study_group !== null && file.study_group !== undefined;
      return fileExistsCheck && studyCheck && studyGroupCheck;
    },
    getWrongFilesList() {
      const items = [];
      for (let i = 0; i < this.items.length; i++) {
        if (!this.isIndexFileOk(i)) {
          items.push({ ...this.items[i] });
        }
      }
      return items;
    },
    getTileTitles(index) {
      if (this.items.length === 0) return [];
      if (this.files_w_same_configuration) return this.items;
      return [this.items[index]];
    },
    getErrorMessage(index) {
      if (this.items.length === 0) return '';
      let item = null;
      if (this.files_w_same_configuration) {
        item = this.items[0]; //eslint-disable-line
      } else {
        item = this.items[index];
      }
      if (item.study === null || item.zone === null || item.section === null || item.artifact === null) {
        return 'You need to fill all the required fields in "File configuration"';
      }
      if (this.items[index].file_exists === true) {
        return 'This file name exists. Please change it by writing one in the text field';
      }
      if (this.items[index].file_exists === null) return '';
      return 'This file is valid';
    },
    getCountryNameById(id) {
      const country = this.study_country_items.find(country => country.id === id);
      return country ? country.name : '';
    },
    submitSave() {
      const promises = [];

      let commonConfig = {};
      if (this.files_w_same_configuration) {
        const firstItem = this.items[0];
        commonConfig = {
          folder: firstItem.folder ? firstItem.folder.id : 1,
          // commentary: firstItem.commentary,
          document_date: firstItem.document_date,
          expiration_date: firstItem.does_it_expire ? firstItem.expiration_date : null,
        };
      }
      this.items.forEach((item) => {
        let formData = {
          title: item.title,
          folder: item.folder ? item.folder.id : 1,
          // commentary: item.commentary,
          document_date: item.document_date,
          expiration_date: item.does_it_expire ? item.expiration_date : null,
        };

        // Aplica una configuración comun si files_w_same_configuration esta activado
        if (this.files_w_same_configuration) {
          formData = { ...formData, ...commonConfig };
        }
        console.log(JSON.stringify(formData));
        if (item.id) {
          promises.push(this.fileServices.preClassification(formData, item.id));
        }
      });

      Promise.all(promises)
        .then((responses) => {
          console.log(responses);
          this.$router.push('/home');
        })
        .catch((error) => {
          console.error('error', error);
        });
    },
  },
};
</script>
<style scoped>
  .preclassification-row {
    padding: 0 2em;
  }
  .preclassification-file-title {
    font-size: 1.4em;
    font-weight: 400;
  }
  .preclassification-title-card {
    padding: 1em;
    background: rgba(0, 0, 0, 0)important;
  }
  .preclassification-toolbar {
    background: none;
  }
  .preclassification-toolbar-style{
    background:none;
  }
  .preclassification-card {
    padding: 3em;
  }
  .select-style {
    margin:  auto;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 120px;
  }
  .save-style {
    width: 250px;
    margin-left: 0px;
    padding-left: 30px;
    margin-top: 6px;
  }
  .check-style {
    margin-left: 15px;
  }
  .alert-box {
    position: absolute;
    left: 70%;
    margin: 0 auto;
  }

  .label-container {
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
  }

  ::v-deep .v-data-table-header__content {
    font-weight: bold !important;
  }
</style>
