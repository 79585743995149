export const Constants = {
  STATUS_RAW_ID: 1,
  STATUS_RAW: 'Raw',
  STATUS_UPLOADED_ID: 2,
  STATUS_UPLOADED: 'Uploaded',
  STATUS_VERIFIED_ID: 3,
  STATUS_VERIFIED: 'Verified',
  STATUS_REJECTED_ID: 4,
  STATUS_REJECTED: 'Rejected',
  STATUS_APPROVED_ID: 5,
  STATUS_APPROVED: 'Approved',
  ACCEPTED_FILE_EXTENSIONS: ['.png', '.jpg', '.jpeg', '.pdf'],
};
