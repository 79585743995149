<template>
  <v-chip :class="getStatusColorClass()">
    <span>{{ status_name || getStatusName() }}</span>
  </v-chip>
</template>

<script>
import { Constants } from '@/store/constants';

export default {
  name: 'DataStatusLabel',
  data() {
    return {
      Constants,
    };
  },
  props: {
    status_id: {
      type: Number,
      required: true,
    },
    status_name: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    getStatusColorClass() {
      if (this.status_id === Constants.STATUS_RAW_ID || this.status_name === Constants.STATUS_RAW) {
        return 'status-label__raw';
      }
      if (this.status_id === Constants.STATUS_UPLOADED_ID || this.status_name === Constants.STATUS_UPLOADED) {
        return 'status-label__uploaded';
      }
      if (this.status_id === Constants.STATUS_VERIFIED_ID || this.status_name === Constants.STATUS_VERIFIED) {
        return 'status-label__verified';
      }
      if (this.status_id === Constants.STATUS_REJECTED_ID || this.status_name === Constants.STATUS_REJECTED) {
        return 'status-label__rejected';
      }
      if (this.status_id === Constants.STATUS_APPROVED_ID || this.status_name === Constants.STATUS_APPROVED) {
        return 'status-label__approved';
      }
      return 'status-label__default';
    },
    getStatusName() {
      if (this.status_id === Constants.STATUS_RAW_ID) {
        return Constants.STATUS_RAW;
      }
      if (this.status_id === Constants.STATUS_UPLOADED_ID) {
        return Constants.STATUS_UPLOADED;
      }
      if (this.status_id === Constants.STATUS_VERIFIED_ID) {
        return Constants.STATUS_VERIFIED;
      }
      if (this.status_id === Constants.STATUS_REJECTED_ID) {
        return Constants.STATUS_REJECTED;
      }
      if (this.status_id === Constants.STATUS_APPROVED_ID) {
        return Constants.STATUS_APPROVED;
      }
      return '--';
    },
  },
};
</script>