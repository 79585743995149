<template>
  <div class="main-container">
    <div class="content">
      <v-container style="max-width: 1200px;">
        <div v-for="(file) in items" :key="file.id">
          <v-card :id="'file-' + file.id" outlined elevation="5" class="validation-card">
            <v-card-title>
              <span class="upload-title" @click="openPdfDialog(file.id, file.name)">
                {{ file.title }}
              </span>
              <v-btn icon class="ml-5 mb-3" @click="openPdfDialog(file.id, file.name)">
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div><strong>Study Group:</strong> {{ file.study_group.name }}</div>
              <div><strong>Submitted by:</strong> {{ file.applicant.first_name }} {{ file.applicant.last_name }}</div>
              <div class="mb-10"><strong>Status:</strong> {{ file.status }}</div>
              <v-textarea class="mt-7" @input="updateItemValidity(file)" :rules="commentaryRules" v-model="file.commentary" label="Verifier Comments"
                outlined auto-grow rows="3"></v-textarea>
              <v-select @update:modelValue="updateItemValidity(file)" v-model="file.newStatus" :rules="statusRules" :items="documentNewStatus"
                label="Select New Status" outlined class="mt-3"></v-select>
            </v-card-text>
            <v-card-actions>
              <div class="actions-container">
                <v-btn class="update-btn px-4 py-2" color="light-blue lighten-2" text :disabled="!file.isValid"
                  @click="updateDocumentStatus(file)">
                  <v-icon dark class="mr-3">mdi-file-restore</v-icon> Update Document
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
          <br />
        </div>
      </v-container>
      <v-dialog v-model="showPdfDialog">
        <v-card class="pdf-card">
          <v-card-title class="pdf-card-title" style="overflow: hidden;">
            <v-btn icon class="pdf-btn" @click="closePdfDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pdf-card-text">
            <div id="pdf-wrapper">
              <PdfViewer :files="selectedPdfFile"></PdfViewer>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { createCoreServices } from '@/services/coreService';
import { createValidationServices } from '@/services/validationService';
import PdfViewer from "@/components/PdfViewer.vue";

export default {
  name: 'ValidationForm',
  components: {
    PdfViewer,
  },
  setup() {
    const files = ref([]);

    const commentaryRules = [v => !!v || 'Commentary is required'];
    const statusRules = [v => !!v || 'Status is required'];
    return {
      commentaryRules,
      statusRules,
      files
    }
  },
  data() {
    return {
      items: [],
      documentNewStatus: ['approved', 'reevaluated'],
      validationsServices: null,
      showPdfDialog: false,
      selectedPdfFile: null,
    };
  },
  mounted() {
    this.coreServicesInstance = createCoreServices();
    this.validationsServices = createValidationServices(this.coreServicesInstance);
    this.getFilesToVerify();
  },
  methods: {
    getFilesToVerify() {
      this.validationsServices.getVerifyFiles()
        .then((response) => {this.items = response.data.map((file) => ({
            ...file,
            newStatus: this.documentNewStatus[1],
            commentary: file.commentary || '',
            isValid: file.commentary.trim(), 
          }));
        }).catch((error) => {
          console.error('Error fetching documents:', error);
        });
    },
    updateDocumentStatus(file) {
      if (file.newStatus === 'approved') {
        this.processDocument(file.id);
      }
      else if (file.newStatus === 'reevaluated') {
        this.reevaluateDocument(file.id);
      }
    },
    updateItemValidity(file) {
      console.log(file.newStatus.trim());
      console.log(file.commentary.trim());
      file.isValid = file.newStatus.trim() && file.commentary.trim();
    },
    processDocument(documentId) {
      const commentary = this.items.find(item => item.id === documentId)?.commentary || '';
      this.validationsServices.processDocument(documentId, commentary)
        .then((response) => {
          console.log('Document processed successfully');
          console.log(JSON.stringify(response));
          this.filterItems(documentId);
        })
        .catch(error => {
          console.error('Error processing document:', error);
          console.log(error);
          toast("Error processing document. Please try again.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
    },
    reevaluateDocument(documentId) {
      const commentary = this.items.find(item => item.id === documentId)?.commentary || '';
      this.validationsServices.reevaluateDocument(documentId, commentary)
        .then((response) => {
          console.log(JSON.stringify(response));
          this.filterItems(documentId);
        })
        .catch(error => {
          console.error('Error setting document for reevaluation:', error);
          console.log(error);
          toast("Error setting document for reevaluation. Please try again.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
    },
    filterItems(id) {
      this.items = this.items.filter(
        (item) => item.id !== id
      );
    },
    openPdfDialog(documentId, name) {
      this.validationsServices.getDocumentSigned(documentId).then((documentData) => {
        if (documentData) {
          const pdfUrl = '/example.pdf'
          this.selectedPdfFile = [{
            url: pdfUrl,
            title: name,
          }];
          this.showPdfDialog = true;
        } else {
          console.error('Document data is undefined.');
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
        console.error('Error fetching document:', error);
        toast("Error fetching document: " + error, {
          type: "error",
          transition: "slide",
          dangerouslyHTMLString: true
        });
      });
      // this.validationsServices.getDocument(documentId).then((documentData) => {
      //   console.log(JSON.stringify(documentData));
      //   if (documentData) {
      //     const pdfUrl = '/example.pdf';
      //     // this.selectedPdfFile = [{
      //     //   // TODO
      //     //   url: documentData.pdfUrl,
      //     //   title: documentData.title,
      //     // }];
      //     this.selectedPdfFile = [{
      //       url: pdfUrl,
      //       title: 'ejemplo',
      //     }];
      //     this.showPdfDialog = true;
      //   } else {
      //     console.error('Document data is undefined.');
      //   }
      // }).catch((error) => {
      //   console.error('Error fetching document:', error);
      // });
    },
    closePdfDialog() {
      this.showPdfDialog = false;
    },
  },
};
</script>

<style scoped>
.content {
  padding: 0 2em;
  max-width: 1200px;
  margin: auto;
}

.validation-card {
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 2em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.v-card-title {
  font-weight: bold;
  font-size: 1.2em;
}

.upload-title {
  font-size: 1.2em;
  margin-bottom: 1em;
  cursor: pointer;
}

.v-card-text {
  padding: 20px;

}

.v-card-text>div {
  margin-bottom: 16px;
}

.actions-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.v-text-field.underlined::before,
.v-select::before {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.v-text-field.no-border,
.v-select {
  border-bottom: none;
}

.update-btn {
  background-color: rgb(var(--v-theme-secondary)) !important;
  color: #000 !important;
  margin: auto;
  padding: 6px 16px;
  text-transform: none;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  padding: 10px 25px;
  min-width: 150px;
  min-height: 40px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.update-btn .v-icon {
  line-height: 1;
}

.validation-row {
  padding: 0 2em;
}

.validation-file-title {
  font-size: 1.4em;
  font-weight: 400;
}

.validation-title-card {
  padding: 1em;
  background: rgba(0, 0, 0, 0)important;
}

.validation-toolbar {
  background: none;
}

.validation-toolbar-style {
  background: none;
}

.validation-card {
  padding: 3em;
}

.select-style {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 120px;
}

.save-style {
  width: 250px;
  margin-left: 0px;
  padding-left: 30px;
  margin-top: 6px;
}

.check-style {
  margin-left: 15px;
}

.alert-box {
  position: absolute;
  left: 70%;
  margin: 0 auto;
}

#pdf-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 650px;
}

.pdf-card {
  background: rgba(166, 185, 194, 0.35);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pdf-card-title {
  padding: 0;
  top: -20px;

}

.pdf-card-text {
  overflow: hidden;
  margin-bottom: 20px;
}

.pdf-btn {
  border-radius: 0;
  box-shadow: none;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
  background: rgba(0, 0, 0, 0);
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
