<template>
	<div class="main-container">
		<div class="content">
			<v-card @dragover="dragover" @dragleave="dragleave" @drop="drop" class="file-card">
				<v-file-input id="file" ref="file" counter multiple name="fileToUpload" placeholder="Select your file"
					prepend-icon="mdi-paperclip" outlined enctype="multipart/form-data" :show-size="1000" class="upload-input"
					@change="checkFile($event)" @click:clear="onFileClear"
					:accept="Constants.ACCEPTED_FILE_EXTENSIONS.join(', ')">
					<template v-slot:selection>
						<v-chip v-for="file in selectedFiles" :key="file.name" dark label small>{{ file.name }}</v-chip>
					</template>
				</v-file-input>
				<v-col>
					<v-row>
						<AlertMessage :colorTextClass="!uploadButtonFail ? '' : 'red--text'"
							:colorIcon="!uploadButtonFail ? 'blue' : 'red'" :icon="'info'"
							:message="acceptedFileExtensionInfoMessage">
						</AlertMessage>
					</v-row>
				</v-col>
				<v-col>
					<v-row>
						<pdf-viewer class="pdf-viewer" v-if="filesEncoded.length > 0" :filesEncoded="filesEncoded">
						</pdf-viewer>
						<div v-if="urls.length > 0">
							<div v-for="url in filesToString" :key="url">
								<img draggable="false" class="preview-img-visualizate" v-show="url" type="image/png" :src="url" />
							</div>
						</div>
					</v-row>
				</v-col>
				<div class="upload-style">
					<v-checkbox v-model="agree">
						<template v-slot:label>
							I agree with the&nbsp;
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a target="_blank" :href="getTermsAndConditionsUrl()" @click.stop v-on="on">
										terms and conditions
									</a>
								</template>
								Terms and conditions
							</v-tooltip>
						</template>
					</v-checkbox>
					<AlertMessage v-if="uploadButtonFail" :klass="'text-center'" :colorTextClass="'red--text'" :colorIcon="'red'"
						:icon="'error'" :message="acceptedFileExtensionInfoMessage"></AlertMessage>
					<div class="text-center">
						<v-btn class="ma-2" color="secondary accent-4" text :disabled="isUploadButtonDisabled()" @click="submitUpload">
							Upload File <v-icon dark class="ml-2">mdi-cloud-upload</v-icon>
						</v-btn>
					</div>
				</div>
			</v-card>
		</div>
	</div>
</template>
<script>
import PdfViewer from "../components/PreviewPdfFile.vue";
import { createCoreServices } from '../services/coreService';
import { createFileServices } from "../services/fileService";
import { environmentService } from "../services/environmentService";
import AlertMessage from "@/components/generic/AlertMessage.vue";
import { Constants } from "../store/constants";

export default {
	name: "uploadFiles",
	components: {
		PdfViewer,
		AlertMessage,
	},
	data() {
		return {
			Constants,
			selectedFiles: [],
			base64Files: [],
			loading: false,
			agree: false,
			alert: false,
			urls: [],
			uploadButtonFail: false,
			coreServicesInstance: null,
			fileServices: null
		};
	},
	mounted() {
		this.coreServicesInstance = createCoreServices();
		this.fileServices = createFileServices(this.coreServicesInstance);
	},
	computed: {
		filesEncoded() {
			return JSON.parse(JSON.stringify(this.base64Files));
		},
		filesToString() {
			return this.urls.map((url) => `data:image/png;base64,${url}`);
		},
		acceptedFileExtensionInfoMessage() {
			return `Only files with extension: ${Constants.ACCEPTED_FILE_EXTENSIONS.join(
				", "
			)}`;
		},
	},
	methods: {
		isUploadButtonDisabled() {
			return (
				this.uploadButtonFail || !this.agree || this.selectedFiles.length === 0
			);
		},
		onFileClear() {
			this.selectedFiles = [];
			this.uploadButtonFail = false;
			this.urls = [];
			this.base64Files = [];
		},
		checkFile(event) {
			this.selectedFiles = [...event.target.files];
			this.validateFiles();
		},
		validateFiles() {
			let fail = false;
			this.urls = [];
			this.base64Files = [];
			this.uploadButtonFail = this.selectedFiles.some((file) => {
				const ext = file.name.split(".").pop();
				if (!Constants.ACCEPTED_FILE_EXTENSIONS.includes(`.${ext}`)) {
					fail = true;
					return true;
				}
				return false;
			});
			if (!fail) {
				this.convertFilesToBase64();
			}
		},
		async convertFilesToBase64() {
			const promises = this.selectedFiles.map((file) =>
				this.fileToBase64(file)
			);
			const results = await Promise.all(promises);
			results.forEach((base64, index) => {
				const ext = this.selectedFiles[index].name.split(".").pop();
				if (ext === "pdf") {
					this.base64Files.push(base64);
				} else {
					this.urls.push(base64);
				}
			});
		},
		fileToBase64(file) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = () => {
					const base64 = reader.result.split(",")[1];
					resolve(base64);
				};
				reader.readAsDataURL(file);
			});
		},
		submitUpload() {
			const archive = document.querySelector("#file");
			this.loading = true;
			const responses = this.fileServices.uploadFile(archive);
			Promise.all(responses)
				.then((results) => {
					results.forEach((res) => {
						this.data = res.data.status;
						if (this.data === "uploaded") {
							this.loading = false;
						} else {
							this.loading = false;
							// TODO Mensaje?
							// logoutServices.logout();
						}
					});
					this.$router.push("/document/preclassification");
				})
				.catch((error) => {
					this.loading = false;
					this.uploadDisabled = !this.uploadDisabled;
					console.log(error);
				});
		},
		dragover(event) {
			event.preventDefault();
		},
		dragleave(event) {
			event.preventDefault();
		},
		drop(event) {
			event.preventDefault();
			this.selectedFiles = [...event.dataTransfer.files];
			this.validateFiles();
		},
		getTermsAndConditionsUrl() {
			return environmentService.getTermsAndConditionsUrl();
		},
	},
};
</script>

<style scoped>
.main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	margin-left: -60px !important;
	margin-top: 70px !important;
	max-width: 1200px;
	padding: 30px;
}

.content {
	width: 100%;
	padding: 20px;
}

.pdf-viewer {
	margin: 0 auto;
	margin-top: 60px !important;
	margin-bottom: 40px !important;
}

.file-card {
	padding: 40px;
}

.upload-input {
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.upload-style {
	margin-left: -7px;
}

.upload-btn {
	margin-bottom: 12px;
}

.alertbox {
	position: fixed;
	left: 70%;
	margin: 0 auto;
}

::v-deep .v-data-table-header__content {
	font-weight: bold !important;
}
</style>../services/fileService
