<template>
  <div>
    <div class="main-container">
      <div class="content">
        <v-row>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>Gráfico de Barras</v-card-title>
              <apexchart type="bar" :options="barChartOptions" :series="barChartOptions.series"></apexchart>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>Gráfico de Línea</v-card-title>
              <apexchart type="line" :options="lineChartOptions" :series="lineChartOptions.series"></apexchart>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col cols="12" sm="6" md="4" v-for="item in cardItems" :key="item.title">
            <v-card>
              <v-img src="@/assets/img/logo.png" height="80px"></v-img>
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-text>{{ item.text }}</v-card-text>
              <v-card-actions>
                <v-btn text color="blue">Más Info</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                Tabla de Datos
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
                  hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="tableItems" :search="search" class="elevation-1"></v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';


export default {
  name: 'HomeView',
  data() {
    return {
      drawer: true,
      urgentAlerts: [],
      cardItems: [
        { title: 'Tarjeta 1', text: 'Descripción de Tarjeta 1', image: 'url-a-imagen-2' },
        { title: 'Tarjeta 2', text: 'Descripción de Tarjeta 2', image: 'url-a-imagen-2' },
        { title: 'Tarjeta 3', text: 'Descripción de Tarjeta 3', image: 'url-a-imagen-3' },
      ],
      headers: [
        { text: 'Header 1', value: 'value1' },
        { text: 'Header 2', value: 'value2' },
      ],
      tableItems: [
        { value1: 'Dato 1', value2: 'Dato 2' },
      ],
      barChartOptions: {
        chart: {
          type: 'bar',
        },
        series: [{
          name: 'serie-1',
          data: [45, 55, 60, 49, 60, 70, 91]
        }],
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997]
        }
      },
      lineChartOptions: {
        chart: {
          type: 'line'
        },
        series: [{
          name: 'serie-1',
          data: [30, 40, 35, 50, 49, 60, 70, 91]
        }],
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997]
        }
      }
    };
  },
  setup() {
    const data = ref(null);
    const store = useStore();

    onMounted(async () => {
      try {
        const res = await fetch("https://jsonplaceholder.typicode.com/todos/1");
        data.value = await res.json();

        store.dispatch('setLoading', false);
        store.dispatch('setAppLoaded');
      } catch (e) {
        console.error(e);
        store.dispatch('setLoading', false);
      }
    });
    return {
      data,
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    onMenuItemClicked(item) {
      console.log('Menu item clicked:', item);
    }
  }
};
</script>

<style scoped>
.v-container {
  padding: 0 !important;
}

.main-container {
  background-color: rgba(48, 180, 241, .07);
}

.content {
  margin-left: 0 !important;
}

.home-style-cards {
  flex: 1;
  margin: 10px;
  min-width: 300px;
  flex-wrap: wrap;
  padding: 16px 10px;
  height: 800px;
  justify-content: center;
  margin-top: 0 !important;
}

.card-title-style {
  margin: 10px;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
