<template>
  <div>
    <div class="main-container">
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel class="mt-5">
          <v-expansion-panel-title>
            <div class="expansion-title">User info</div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="expansion-content">
            <v-row class="user-info-style">
              <v-col cols="7">
                <div class="label-container ml-0">
                  <div class="label">Email</div>
                  <div class="value">{{ email }}</div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="label-container ml-6">
                  <div class="label">Role</div>
                  <div class="value">{{ role }}</div>
                </div>
              </v-col>

            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel class="mb-5">
          <v-expansion-panel-title>
            <div class="expansion-title">Change your password</div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="expansion-content">
            <change-password class="mt-8 mx-auto" max-width="900" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
import ChangePassword from '../components/ChangePassword.vue';
import { toast } from "vue3-toastify";

export default {
  name: 'UserProfile',
  components: {
    ChangePassword,
  },
  data() {
    return {
      panels: [0, 1],
      role: '',
      email: '',
      fullName: '',
      updateUsername: false,
      coreServicesInstance: null,
      userServices: null
    };
  },
  mounted() {
    this.coreServicesInstance = createCoreServices();
    this.userServices = createUserServices(this.coreServicesInstance);
    this.checkUserData();
  },
  methods: {
    checkUserData() {
      this.userServices
        .checkUserData()
        .then((response) => {
          this.role = response.data.role;
          this.email = response.data.email;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateName() {
      const saveName = document.getElementById('saveUsername');
      saveName.classList.add('show');
      this.updateUsername = !this.updateUsername;
    },
    saveUsername() {
      const saveName = document.getElementById('saveUsername');
      saveName.classList.remove('show');
      this.data = {
        first_name: this.register_name,
        last_name: this.register_last_name,
        id: this.id,
      };
      this.userServices.updateUsername(this.id, this.data)
        .then((response) => {
          this.fullName = `${response.data.first_name} ${response.data.last_name}`;
          toast.success(`${response.data.first_name} ${response.data.last_name} successfully updated.`);
          this.updateUsername = false;
          setTimeout(() => {
            toast.error("There was an error updating the username. Please try again.");
          }, 2000);
        });
    },
  },
};
</script>

<style scoped>
.user-info-style {
  padding: 0;
  margin-left: 15px;
  max-width: 50%;
}

.v-expansion-panel {
  max-width: 95%;
}

.expansion-content {
  padding: 2em;
}


.profile-style {
  width: 900px;
  padding: 24px;
  margin: auto;
}

.register-name-style {
  width: 30%;
  font-size: 32px !important;
  margin-left: 24px;
  text-decoration-color: white !important;
}

.save-username {
  margin-top: 6px;
}

.show {
  display: flex !important;
}

.hide {
  display: none;
}

.v-btn__content {
  margin: 16px;
}

.label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-weight: bold;
  margin-bottom: 4px;
}

.expansion-title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>